﻿$rotating-360: 360deg;

// Blinker
@keyframes blinker {
    50% {
        opacity: 0.6;
    }
}

@-webkit-keyframes blinker {
    50% {
        opacity: 0.6;
    }
}

// Fade in
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// Ellipsis
@keyframes ellipsis {
    to {
        width: 20px;
        margin-right: 0px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

// Rotating
@keyframes rotating {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate($rotating-360);
        transform: rotate($rotating-360);
    }
}

@-webkit-keyframes rotating {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate($rotating-360);
        transform: rotate($rotating-360);
    }
}

// Rotating reverse

@keyframes rotating-reverse {
    from {
        -webkit-transform: rotate($rotating-360);
        transform: rotate($rotating-360);
    }

    to {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@-webkit-keyframes rotating-reverse {
    from {
        -webkit-transform: rotate($rotating-360);
        transform: rotate($rotating-360);
    }

    to {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@keyframes collapsed-animation {
    0% {
        opacity: 0;
    }


    100% {
        opacity: 1;
    }
}

@keyframes expanded-animation {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes expanded-scroll {
    0% {
        overflow-y: hidden;
    }

    100% {
        overflow-y: auto;
    }
}

// CLASSES

.animated-ellipsis {
    width: 1.25em;
    display: inline-block;

    &:after {
        $animationDuration: 900ms;

        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) $animationDuration infinite;
        animation: ellipsis steps(4, end) $animationDuration infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0px;
        margin-right: 20px;
    }
}

.animate-fade-out {
    $animationDuration: 400ms;
    -webkit-transition: $animationDuration linear all;
    transition: $animationDuration linear all;
    opacity: 1;

    &.ng-leave.ng-leave-active,
    &.ng-move,
    &.ng-enter {
        opacity: 0;
    }

    &.ng-leave,
    &.ng-move.ng-move-active,
    &.ng-enter.ng-enter-active {
        opacity: 1;
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.rotating-reverse {
    -webkit-animation: rotating-reverse 1.5s linear infinite;
    animation: rotating-reverse 1.5s linear infinite;
}

span, i {
    &.rotation, &.rotating-reverse {
        display: inline-block;
    }
}

.wait-loaded {
    &.loaded {
        & > * {
            animation: fadeIn 1s;
        }
    }
}