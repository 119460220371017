@import "colors";

$mf-border-radius-24: 24px; // Panels (listing, metadata, preview)
$mf-border-radius-20: 20px; // Dialog and search
$mf-border-radius-16: 16px; // Buttons
$mf-border-radius-8: 8px; // Menu, menu item, text field


/// Apply border-radius on a component
///
/// @param {variable || value} $radius
@mixin mf-border-radius($radius: $mf-border-radius-8) {
    & {
        border-radius: $radius;
    }
}

@mixin border-stroke {
    & {
        border: 1px solid $mf-color-night-sky-20;
    }
}

@mixin border-radius-24 {
    & {
        border-radius: $mf-border-radius-24;
    }
}

@mixin border-radius-20 {
    & {
        border-radius: $mf-border-radius-20;
    }
}

@mixin border-radius-16 {
    & {
        border-radius: $mf-border-radius-16;
    }
}

@mixin border-radius-8 {
    & {
        border-radius: $mf-border-radius-8;
    }
}

@mixin border-radius-end-8 {
    & {
        border-radius: $mf-border-radius-8;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
