@import "variables";

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-deselect {
    &:before {
        content: $icon-deselect;
    }
}

.icon-export {
    &:before {
        content: $icon-export;
    }
}

.icon-import {
    &:before {
        content: $icon-import;
    }
}

.icon-draggable {
    &:before {
        content: $icon-draggable;
    }
}

.icon-notification-info {
    &:before {
        content: $icon-notification-info;
    }
}

.icon-sso {
    &:before {
        content: $icon-sso;
    }
}

.icon-reminder {
    &:before {
        content: $icon-reminder;
    }
}

.icon-circle-check-empty-checked {
    &:before {
        content: $icon-circle-check-empty-checked;
    }
}

.icon-task {
    &:before {
        content: $icon-task;
    }
}

.icon-circle-check-empty {
    &:before {
        content: $icon-circle-check-empty;
    }
}

.icon-circle-check {
    &:before {
        content: $icon-circle-check;
    }
}

.icon-cabinet {
    &:before {
        content: $icon-cabinet;
    }
}

.icon-certificate {
    &:before {
        content: $icon-certificate;
    }
}

.icon-sign {
    &:before {
        content: $icon-sign;
    }
}

.icon-edit {
    &:before {
        content: $icon-edit;
    }
}

.icon-spinner {
    &:before {
        content: $icon-spinner;
    }
}

.icon-spinner-5 {
    &:before {
        content: $icon-spinner-5;
    }
}

.icon-loading {
    &:before {
        content: $icon-loading;
    }
}

.icon-paperplane-send {
    &:before {
        content: $icon-paperplane-send;
    }
}

.icon-selection {
    &:before {
        content: $icon-selection;
    }
}

.icon-kanban {
    &:before {
        content: $icon-kanban;
    }
}

.icon-gantt {
    &:before {
        content: $icon-gantt;
    }
}

.icon-table {
    &:before {
        content: $icon-table;
    }
}

.icon-checkbox-unchecked {
    &:before {
        content: $icon-checkbox-unchecked;
    }
}

.icon-external-link {
    &:before {
        content: $icon-external-link;
    }
}

.icon-link-out {
    &:before {
        content: $icon-link-out;
    }
}

.icon-remove {
    &:before {
        content: $icon-remove;
    }
}

.icon-bin {
    &:before {
        content: $icon-bin;
    }
}

.icon-trash {
    &:before {
        content: $icon-trash;
    }
}

.icon-loop {
    &:before {
        content: $icon-loop;
    }
}

.icon-cycle {
    &:before {
        content: $icon-cycle;
    }
}

.icon-new {
    &:before {
        content: $icon-new;
    }
}

.icon-pause {
    &:before {
        content: $icon-pause;
    }
}

.icon-pause-circle-empty {
    &:before {
        content: $icon-pause-circle-empty;
    }
}

.icon-play {
    &:before {
        content: $icon-play;
    }
}

.icon-play-circle {
    &:before {
        content: $icon-play-circle;
    }
}

.icon-play-circle-empty {
    &:before {
        content: $icon-play-circle-empty;
    }
}

.icon-previous {
    &:before {
        content: $icon-previous;
    }
}

.icon-stop {
    &:before {
        content: $icon-stop;
    }
}

.icon-film {
    &:before {
        content: $icon-film;
    }
}

.icon-camera-movie {
    &:before {
        content: $icon-camera-movie;
    }
}

.icon-bubble {
    &:before {
        content: $icon-bubble;
    }
}

.icon-location {
    &:before {
        content: $icon-location;
    }
}

.icon-bubble-empty {
    &:before {
        content: $icon-bubble-empty;
    }
}

.icon-topic {
    &:before {
        content: $icon-topic;
    }
}

.icon-phone {
    &:before {
        content: $icon-phone;
    }
}

.icon-voicemail {
    &:before {
        content: $icon-voicemail;
    }
}

.icon-mail {
    &:before {
        content: $icon-mail;
    }
}

.icon-rss {
    &:before {
        content: $icon-rss;
    }
}

.icon-add {
    &:before {
        content: $icon-add;
    }
}

.icon-plus {
    &:before {
        content: $icon-plus;
    }
}

.icon-add-box-full {
    &:before {
        content: $icon-add-box-full;
    }
}

.icon-plus-square {
    &:before {
        content: $icon-plus-square;
    }
}

.icon-add-circle-full {
    &:before {
        content: $icon-add-circle-full;
    }
}

.icon-circle-plus {
    &:before {
        content: $icon-circle-plus;
    }
}

.icon-plus-round {
    &:before {
        content: $icon-plus-round;
    }
}

.icon-add-circle-empty {
    &:before {
        content: $icon-add-circle-empty;
    }
}

.icon-box-add {
    &:before {
        content: $icon-box-add;
    }
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-cross {
    &:before {
        content: $icon-cross;
    }
}

.icon-copy {
    &:before {
        content: $icon-copy;
    }
}

.icon-scissors {
    &:before {
        content: $icon-scissors;
    }
}

.icon-task-folder {
    &:before {
        content: $icon-task-folder;
    }
}

.icon-pencil {
    &:before {
        content: $icon-pencil;
    }
}

.icon-flag {
    &:before {
        content: $icon-flag;
    }
}

.icon-remove-circle {
    &:before {
        content: $icon-remove-circle;
    }
}

.icon-circle-minus {
    &:before {
        content: $icon-circle-minus;
    }
}

.icon-remove-circle-empty {
    &:before {
        content: $icon-remove-circle-empty;
    }
}

.icon-reply {
    &:before {
        content: $icon-reply;
    }
}

.icon-reply-all {
    &:before {
        content: $icon-reply-all;
    }
}

.icon-box-remove {
    &:before {
        content: $icon-box-remove;
    }
}

.icon-flag-empty {
    &:before {
        content: $icon-flag-empty;
    }
}

.icon-alarms {
    &:before {
        content: $icon-alarms;
    }
}

.icon-clock-empty {
    &:before {
        content: $icon-clock-empty;
    }
}

.icon-airplane {
    &:before {
        content: $icon-airplane;
    }
}

.icon-battery {
    &:before {
        content: $icon-battery;
    }
}

.icon-coin {
    &:before {
        content: $icon-coin;
    }
}

.icon-money {
    &:before {
        content: $icon-money;
    }
}

.icon-edit-full {
    &:before {
        content: $icon-edit-full;
    }
}

.icon-indent-decrease {
    &:before {
        content: $icon-indent-decrease;
    }
}

.icon-indent-increase {
    &:before {
        content: $icon-indent-increase;
    }
}

.icon-list {
    &:before {
        content: $icon-list;
    }
}

.icon-list-number {
    &:before {
        content: $icon-list-number;
    }
}

.icon-calendar-empty {
    &:before {
        content: $icon-calendar-empty;
    }
}

.icon-image {
    &:before {
        content: $icon-image;
    }
}

.icon-picture {
    &:before {
        content: $icon-picture;
    }
}

.icon-graph {
    &:before {
        content: $icon-graph;
    }
}

.icon-cloud {
    &:before {
        content: $icon-cloud;
    }
}

.icon-cloud-download {
    &:before {
        content: $icon-cloud-download;
    }
}

.icon-download {
    &:before {
        content: $icon-download;
    }
}

.icon-upload {
    &:before {
        content: $icon-upload;
    }
}

.icon-upload-flat {
    &:before {
        content: $icon-upload-flat;
    }
}

.icon-folder {
    &:before {
        content: $icon-folder;
    }
}

.icon-folder-user {
    &:before {
        content: $icon-folder-user;
    }
}

.icon-folder-plus {
    &:before {
        content: $icon-folder-plus;
    }
}

.icon-keyboard {
    &:before {
        content: $icon-keyboard;
    }
}

.icon-arrow-down-bold {
    &:before {
        content: $icon-arrow-down-bold;
    }
}

.icon-arrow-left-bold {
    &:before {
        content: $icon-arrow-left-bold;
    }
}

.icon-arrow-right-bold {
    &:before {
        content: $icon-arrow-right-bold;
    }
}

.icon-arrow-up-bold {
    &:before {
        content: $icon-arrow-up-bold;
    }
}

.icon-mobile {
    &:before {
        content: $icon-mobile;
    }
}

.icon-security {
    &:before {
        content: $icon-security;
    }
}

.icon-tablet {
    &:before {
        content: $icon-tablet;
    }
}

.icon-screen {
    &:before {
        content: $icon-screen;
    }
}

.icon-camera {
    &:before {
        content: $icon-camera;
    }
}

.icon-images {
    &:before {
        content: $icon-images;
    }
}

.icon-pictures {
    &:before {
        content: $icon-pictures;
    }
}

.icon-colors {
    &:before {
        content: $icon-colors;
    }
}

.icon-eye {
    &:before {
        content: $icon-eye;
    }
}

.icon-timer {
    &:before {
        content: $icon-timer;
    }
}

.icon-stopwatch {
    &:before {
        content: $icon-stopwatch;
    }
}

.icon-brightness {
    &:before {
        content: $icon-brightness;
    }
}

.icon-cart {
    &:before {
        content: $icon-cart;
    }
}

.icon-printer {
    &:before {
        content: $icon-printer;
    }
}

.icon-cutlery {
    &:before {
        content: $icon-cutlery;
    }
}

.icon-bubble-edit {
    &:before {
        content: $icon-bubble-edit;
    }
}

.icon-restaurant {
    &:before {
        content: $icon-restaurant;
    }
}

.icon-apps {
    &:before {
        content: $icon-apps;
    }
}

.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}

.icon-triangle-down {
    &:before {
        content: $icon-triangle-down;
    }
}

.icon-caret-down {
    &:before {
        content: $icon-caret-down;
    }
}

.icon-triangle-up {
    &:before {
        content: $icon-triangle-up;
    }
}

.icon-caret-up {
    &:before {
        content: $icon-caret-up;
    }
}

.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}

.icon-cancel {
    &:before {
        content: $icon-cancel;
    }
}

.icon-checkmark {
    &:before {
        content: $icon-checkmark;
    }
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}

.icon-expand-large {
    &:before {
        content: $icon-expand-large;
    }
}

.icon-fullscreen {
    &:before {
        content: $icon-fullscreen;
    }
}

.icon-shrink {
    &:before {
        content: $icon-shrink;
    }
}

.icon-fullscreen-exit {
    &:before {
        content: $icon-fullscreen-exit;
    }
}

.icon-menu {
    &:before {
        content: $icon-menu;
    }
}

.icon-navicon {
    &:before {
        content: $icon-navicon;
    }
}

.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}

.icon-spinner-4 {
    &:before {
        content: $icon-spinner-4;
    }
}

.icon-triangle-left {
    &:before {
        content: $icon-triangle-left;
    }
}

.icon-caret-left {
    &:before {
        content: $icon-caret-left;
    }
}

.icon-triangle-right {
    &:before {
        content: $icon-triangle-right;
    }
}

.icon-caret-right {
    &:before {
        content: $icon-caret-right;
    }
}

.icon-calendar-check {
    &:before {
        content: $icon-calendar-check;
    }
}

.icon-calendar-times {
    &:before {
        content: $icon-calendar-times;
    }
}

.icon-bubble-alert {
    &:before {
        content: $icon-bubble-alert;
    }
}

.icon-bubble-video {
    &:before {
        content: $icon-bubble-video;
    }
}

.icon-cake {
    &:before {
        content: $icon-cake;
    }
}

.icon-bell {
    &:before {
        content: $icon-bell;
    }
}

.icon-bell-on {
    &:before {
        content: $icon-bell-on;
    }
}

.icon-users {
    &:before {
        content: $icon-users;
    }
}

.icon-users-empty {
    &:before {
        content: $icon-users-empty;
    }
}

.icon-users-double {
    &:before {
        content: $icon-users-double;
    }
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}

.icon-user-empty {
    &:before {
        content: $icon-user-empty;
    }
}

.icon-share {
    &:before {
        content: $icon-share;
    }
}

.icon-check-box {
    &:before {
        content: $icon-check-box;
    }
}

.icon-star {
    &:before {
        content: $icon-star;
    }
}

.icon-star-o {
    &:before {
        content: $icon-star-o;
    }
}

.icon-monument {
    &:before {
        content: $icon-monument;
    }
}

.icon-assignment-late {
    &:before {
        content: $icon-assignment-late;
    }
}

.icon-bookmark {
    &:before {
        content: $icon-bookmark;
    }
}

.icon-bug {
    &:before {
        content: $icon-bug;
    }
}

.icon-code {
    &:before {
        content: $icon-code;
    }
}

.icon-creditcard {
    &:before {
        content: $icon-creditcard;
    }
}

.icon-credit-card {
    &:before {
        content: $icon-credit-card;
    }
}

.icon-credit {
    &:before {
        content: $icon-credit;
    }
}

.icon-heart {
    &:before {
        content: $icon-heart;
    }
}

.icon-heart-empty {
    &:before {
        content: $icon-heart-empty;
    }
}

.icon-history {
    &:before {
        content: $icon-history;
    }
}

.icon-lock {
    &:before {
        content: $icon-lock;
    }
}

.icon-info {
    &:before {
        content: $icon-info;
    }
}

.icon-info-circle {
    &:before {
        content: $icon-info-circle;
    }
}

.icon-info-circle-o {
    &:before {
        content: $icon-info-circle-o;
    }
}

.icon-info-circle-empty {
    &:before {
        content: $icon-info-circle-empty;
    }
}

.icon-open {
    &:before {
        content: $icon-open;
    }
}

.icon-unlocked {
    &:before {
        content: $icon-unlocked;
    }
}

.icon-lock-open {
    &:before {
        content: $icon-lock-open;
    }
}

.icon-switch {
    &:before {
        content: $icon-switch;
    }
}

.icon-search {
    &:before {
        content: $icon-search;
    }
}

.icon-unlike {
    &:before {
        content: $icon-unlike;
    }
}

.icon-like {
    &:before {
        content: $icon-like;
    }
}

.icon-shield-check {
    &:before {
        content: $icon-shield-check;
    }
}

.icon-eye-blocked {
    &:before {
        content: $icon-eye-blocked;
    }
}

.icon-portfolio {
    &:before {
        content: $icon-portfolio;
    }
}

.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}

.icon-zoom-in {
    &:before {
        content: $icon-zoom-in;
    }
}

.icon-zoom-out {
    &:before {
        content: $icon-zoom-out;
    }
}

.icon-gif {
    &:before {
        content: $icon-gif;
    }
}

.icon-lightbulb {
    &:before {
        content: $icon-lightbulb;
    }
}

.icon-calendar-range {
    &:before {
        content: $icon-calendar-range;
    }
}

.icon-stats {
    &:before {
        content: $icon-stats;
    }
}

.icon-update {
    &:before {
        content: $icon-update;
    }
}

.icon-clock {
    &:before {
        content: $icon-clock;
    }
}

.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}

.icon-filter {
    &:before {
        content: $icon-filter;
    }
}

.icon-bookmarks {
    &:before {
        content: $icon-bookmarks;
    }
}

.icon-folder-upload {
    &:before {
        content: $icon-folder-upload;
    }
}

.icon-project {
    &:before {
        content: $icon-project;
    }
}

.icon-exit {
    &:before {
        content: $icon-exit;
    }
}

.icon-logout {
    &:before {
        content: $icon-logout;
    }
}

.icon-shield {
    &:before {
        content: $icon-shield;
    }
}

.icon-speed {
    &:before {
        content: $icon-speed;
    }
}

.icon-address-book {
    &:before {
        content: $icon-address-book;
    }
}

.icon-bolt {
    &:before {
        content: $icon-bolt;
    }
}

.icon-lightning {
    &:before {
        content: $icon-lightning;
    }
}

.icon-trophy {
    &:before {
        content: $icon-trophy;
    }
}

.icon-anchor {
    &:before {
        content: $icon-anchor;
    }
}

.icon-arrow-down-circle {
    &:before {
        content: $icon-arrow-down-circle;
    }
}

.icon-arrow-up-circle {
    &:before {
        content: $icon-arrow-up-circle;
    }
}

.icon-dashboard {
    &:before {
        content: $icon-dashboard;
    }
}

.icon-wand {
    &:before {
        content: $icon-wand;
    }
}

.icon-blocked {
    &:before {
        content: $icon-blocked;
    }
}

.icon-calculate {
    &:before {
        content: $icon-calculate;
    }
}

.icon-campaign {
    &:before {
        content: $icon-campaign;
    }
}

.icon-circle {
    &:before {
        content: $icon-circle;
    }
}

.icon-bubble-mark {
    &:before {
        content: $icon-bubble-mark;
    }
}

.icon-wrench {
    &:before {
        content: $icon-wrench;
    }
}

.icon-tools {
    &:before {
        content: $icon-tools;
    }
}

.icon-enter {
    &:before {
        content: $icon-enter;
    }
}

.icon-login {
    &:before {
        content: $icon-login;
    }
}

.icon-bubble-check {
    &:before {
        content: $icon-bubble-check;
    }
}

.icon-bubble-unread {
    &:before {
        content: $icon-bubble-unread;
    }
}

.icon-user-add-empty {
    &:before {
        content: $icon-user-add-empty;
    }
}

.icon-user-add {
    &:before {
        content: $icon-user-add;
    }
}

.icon-user-plus {
    &:before {
        content: $icon-user-plus;
    }
}

.icon-pushpin {
    &:before {
        content: $icon-pushpin;
    }
}

.icon-qrcode {
    &:before {
        content: $icon-qrcode;
    }
}

.icon-support {
    &:before {
        content: $icon-support;
    }
}

.icon-add_task {
    &:before {
        content: $icon-add_task;
    }
}

.icon-circle-cross {
    &:before {
        content: $icon-circle-cross;
    }
}

.icon-grid {
    &:before {
        content: $icon-grid;
    }
}

.icon-th-large {
    &:before {
        content: $icon-th-large;
    }
}

.icon-move {
    &:before {
        content: $icon-move;
    }
}

.icon-square-cross {
    &:before {
        content: $icon-square-cross;
    }
}

.icon-vcard {
    &:before {
        content: $icon-vcard;
    }
}

.icon-archive {
    &:before {
        content: $icon-archive;
    }
}

.icon-box {
    &:before {
        content: $icon-box;
    }
}

.icon-browser {
    &:before {
        content: $icon-browser;
    }
}

.icon-database {
    &:before {
        content: $icon-database;
    }
}

.icon-disk-card {
    &:before {
        content: $icon-disk-card;
    }
}

.icon-disk {
    &:before {
        content: $icon-disk;
    }
}

.icon-ellipsis-v {
    &:before {
        content: $icon-ellipsis-v;
    }
}

.icon-ellipsis-h {
    &:before {
        content: $icon-ellipsis-h;
    }
}

.icon-flow-branch {
    &:before {
        content: $icon-flow-branch;
    }
}

.icon-flow-cascade {
    &:before {
        content: $icon-flow-cascade;
    }
}

.icon-flow-line {
    &:before {
        content: $icon-flow-line;
    }
}

.icon-flow-parallel {
    &:before {
        content: $icon-flow-parallel;
    }
}

.icon-tree {
    &:before {
        content: $icon-tree;
    }
}

.icon-flow-tree {
    &:before {
        content: $icon-flow-tree;
    }
}

.icon-hourglass {
    &:before {
        content: $icon-hourglass;
    }
}

.icon-busy {
    &:before {
        content: $icon-busy;
    }
}

.icon-home {
    &:before {
        content: $icon-home;
    }
}

.icon-house {
    &:before {
        content: $icon-house;
    }
}

.icon-language {
    &:before {
        content: $icon-language;
    }
}

.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}

.icon-link {
    &:before {
        content: $icon-link;
    }
}

.icon-hyperlink {
    &:before {
        content: $icon-hyperlink;
    }
}

.icon-minus {
    &:before {
        content: $icon-minus;
    }
}

.icon-line {
    &:before {
        content: $icon-line;
    }
}

.icon-music-double {
    &:before {
        content: $icon-music-double;
    }
}

.icon-attachment {
    &:before {
        content: $icon-attachment;
    }
}

.icon-paperplane {
    &:before {
        content: $icon-paperplane;
    }
}

.icon-pie {
    &:before {
        content: $icon-pie;
    }
}

.icon-pie-chart {
    &:before {
        content: $icon-pie-chart;
    }
}

.icon-popup {
    &:before {
        content: $icon-popup;
    }
}

.icon-question {
    &:before {
        content: $icon-question;
    }
}

.icon-retweet {
    &:before {
        content: $icon-retweet;
    }
}

.icon-rocket {
    &:before {
        content: $icon-rocket;
    }
}

.icon-signal {
    &:before {
        content: $icon-signal;
    }
}

.icon-connection {
    &:before {
        content: $icon-connection;
    }
}

.icon-ticket {
    &:before {
        content: $icon-ticket;
    }
}

.icon-vcard-empty {
    &:before {
        content: $icon-vcard-empty;
    }
}

.icon-hub-icon-large {
    &:before {
        content: $icon-hub-icon-large;
    }
}

.icon-hub-logo {
    &:before {
        content: $icon-hub-logo;
    }
}

.icon-transfer {
    &:before {
        content: $icon-transfer;
    }
}

.icon-admin {
    &:before {
        content: $icon-admin;
    }
}

.icon-barcode {
    &:before {
        content: $icon-barcode;
    }
}

.icon-binoculars {
    &:before {
        content: $icon-binoculars;
    }
}

.icon-bullhorn {
    &:before {
        content: $icon-bullhorn;
    }
}

.icon-megaphone {
    &:before {
        content: $icon-megaphone;
    }
}

.icon-circle-down {
    &:before {
        content: $icon-circle-down;
    }
}

.icon-down {
    &:before {
        content: $icon-down;
    }
}

.icon-circle-bottom {
    &:before {
        content: $icon-circle-bottom;
    }
}

.icon-circle-full {
    &:before {
        content: $icon-circle-full;
    }
}

.icon-circle-bullet {
    &:before {
        content: $icon-circle-bullet;
    }
}

.icon-circle-left {
    &:before {
        content: $icon-circle-left;
    }
}

.icon-left {
    &:before {
        content: $icon-left;
    }
}

.icon-circle-previous {
    &:before {
        content: $icon-circle-previous;
    }
}

.icon-arrow-circle-left {
    &:before {
        content: $icon-arrow-circle-left;
    }
}

.icon-circle-right {
    &:before {
        content: $icon-circle-right;
    }
}

.icon-right {
    &:before {
        content: $icon-right;
    }
}

.icon-circle-next {
    &:before {
        content: $icon-circle-next;
    }
}

.icon-arrow-circle-right {
    &:before {
        content: $icon-arrow-circle-right;
    }
}

.icon-circle-up {
    &:before {
        content: $icon-circle-up;
    }
}

.icon-up {
    &:before {
        content: $icon-up;
    }
}

.icon-circle-top {
    &:before {
        content: $icon-circle-top;
    }
}

.icon-clipboard {
    &:before {
        content: $icon-clipboard;
    }
}

.icon-cog {
    &:before {
        content: $icon-cog;
    }
}

.icon-compass {
    &:before {
        content: $icon-compass;
    }
}

.icon-console {
    &:before {
        content: $icon-console;
    }
}

.icon-earth {
    &:before {
        content: $icon-earth;
    }
}

.icon-equalizer {
    &:before {
        content: $icon-equalizer;
    }
}

.icon-file-excel {
    &:before {
        content: $icon-file-excel;
    }
}

.icon-file-powerpoint {
    &:before {
        content: $icon-file-powerpoint;
    }
}

.icon-file-xml {
    &:before {
        content: $icon-file-xml;
    }
}

.icon-file-zip {
    &:before {
        content: $icon-file-zip;
    }
}

.icon-zip {
    &:before {
        content: $icon-zip;
    }
}

.icon-file {
    &:before {
        content: $icon-file;
    }
}

.icon-forward {
    &:before {
        content: $icon-forward;
    }
}

.icon-send {
    &:before {
        content: $icon-send;
    }
}

.icon-key {
    &:before {
        content: $icon-key;
    }
}

.icon-library {
    &:before {
        content: $icon-library;
    }
}

.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}

.icon-map {
    &:before {
        content: $icon-map;
    }
}

.icon-map-empty {
    &:before {
        content: $icon-map-empty;
    }
}

.icon-newspaper {
    &:before {
        content: $icon-newspaper;
    }
}

.icon-notebook {
    &:before {
        content: $icon-notebook;
    }
}

.icon-office {
    &:before {
        content: $icon-office;
    }
}

.icon-paste {
    &:before {
        content: $icon-paste;
    }
}

.icon-paste-full {
    &:before {
        content: $icon-paste-full;
    }
}

.icon-pen {
    &:before {
        content: $icon-pen;
    }
}

.icon-podcast {
    &:before {
        content: $icon-podcast;
    }
}

.icon-point-right {
    &:before {
        content: $icon-point-right;
    }
}

.icon-point-up {
    &:before {
        content: $icon-point-up;
    }
}

.icon-profile {
    &:before {
        content: $icon-profile;
    }
}

.icon-quill {
    &:before {
        content: $icon-quill;
    }
}

.icon-quote {
    &:before {
        content: $icon-quote;
    }
}

.icon-settings {
    &:before {
        content: $icon-settings;
    }
}

.icon-spam {
    &:before {
        content: $icon-spam;
    }
}

.icon-stack {
    &:before {
        content: $icon-stack;
    }
}

.icon-stop-circle {
    &:before {
        content: $icon-stop-circle;
    }
}

.icon-storage {
    &:before {
        content: $icon-storage;
    }
}

.icon-subtract {
    &:before {
        content: $icon-subtract;
    }
}

.icon-tag {
    &:before {
        content: $icon-tag;
    }
}

.icon-volume-decrease {
    &:before {
        content: $icon-volume-decrease;
    }
}

.icon-volume-empty {
    &:before {
        content: $icon-volume-empty;
    }
}

.icon-volume-high {
    &:before {
        content: $icon-volume-high;
    }
}

.icon-volume-increase {
    &:before {
        content: $icon-volume-increase;
    }
}

.icon-volume-low {
    &:before {
        content: $icon-volume-low;
    }
}

.icon-volume-medium {
    &:before {
        content: $icon-volume-medium;
    }
}

.icon-volume-mute {
    &:before {
        content: $icon-volume-mute;
    }
}

.icon-asterisk {
    &:before {
        content: $icon-asterisk;
    }
}

.icon-book {
    &:before {
        content: $icon-book;
    }
}

.icon-cubes {
    &:before {
        content: $icon-cubes;
    }
}

.icon-cursor {
    &:before {
        content: $icon-cursor;
    }
}

.icon-warning {
    &:before {
        content: $icon-warning;
    }
}

.icon-exclamation {
    &:before {
        content: $icon-exclamation;
    }
}

.icon-exclamation-triangle {
    &:before {
        content: $icon-exclamation-triangle;
    }
}

.icon-notification {
    &:before {
        content: $icon-notification;
    }
}

.icon-help {
    &:before {
        content: $icon-help;
    }
}

.icon-sort {
    &:before {
        content: $icon-sort;
    }
}

.icon-sort-alpha-asc {
    &:before {
        content: $icon-sort-alpha-asc;
    }
}

.icon-sort-alpha-desc {
    &:before {
        content: $icon-sort-alpha-desc;
    }
}

.icon-sort-amount-asc {
    &:before {
        content: $icon-sort-amount-asc;
    }
}

.icon-sort-amount-desc {
    &:before {
        content: $icon-sort-amount-desc;
    }
}

.icon-sort-numeric-asc {
    &:before {
        content: $icon-sort-numeric-asc;
    }
}

.icon-sort-numeric-desc {
    &:before {
        content: $icon-sort-numeric-desc;
    }
}
