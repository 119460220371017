﻿@import "../shared/colors";
@import "../shared/typography";
@import "../shared/borders";
@import "../shared/spacing";
@import "../mixins";

/*Global overrides of bootstrap classes which are not variables (see theme.scss file in this case) */

strong {
    font-weight: 600;
}

// PLACEHOLDER
input,
textarea {
    @include placeholder() {
        color: $mf-color-night-sky-60;
    }
}

// DROPDOWN
.dropdown-toggle.show-caret {
    &::after {
        display: inline-block !important;
    }
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    border: 1px solid $mf-color-night-sky-20 !important;
    padding: 8px 0 !important;
    @include border-radius-8;
    @include box-shadow($strong-shadow);

    .dropdown-item {
        padding: 8px 16px;
        cursor: pointer;

        *::first-letter {
            text-transform: uppercase;
        }

        &.disabled {
            cursor: default;
            color: $mf-color-night-sky-60 !important;
        }

        &:hover {
            background-color: $mf-color-deep-blue-5;
        }

        &:focus,
        &.selected {
            background-color: $mf-color-deep-blue-20;
        }

        img {
            vertical-align: baseline;
            margin-right: 2px;
        }

        a,
        button,
        li,
        &:is(a),
        &:is(button),
        &:is(li) {
            display: block;
            color: $mf-color-night-sky-100 !important;
        }
    }

    &.dropdown-menu-lg {
        .dropdown-item {
            @include dropdown-item-padding(.5rem);
        }
    }
}

// INPUT LOCALIZED - override dropdown default
.input-localized {
    top: 1px !important;
    z-index: 1055;

    .dropdown-item {
        @include dropdown-item-padding(.5rem .75rem);
    }
}

.form-check-input[type=radio] {
    flex-shrink: 0;

    &:disabled {
        background-image: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='transparent'/></svg>"))} !important;
        pointer-events: none;
        filter: none;
        border-color: $mf-color-night-sky-20;
        opacity: 1;

        &:checked {
            border-color: $mf-color-blue-moment-20;
            background-image: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$mf-color-blue-moment-20}'/></svg>"))} !important;

        }
    }

    &:checked {
        background-image: #{escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$mf-color-blue-moment-100}'/></svg>") )} !important;
        background-color: transparent;
    }
}


// TABLE
.table-responsive {
    @include modern-scrollbar;
}

table {
    thead {
        @include typography-headline-7;

        th {
            font-weight: 600;
        }
    }

    th,
    td {
        vertical-align: middle;
    }

    &:not(.table-m-files-tree) {
        border: 1px solid $mf-color-night-sky-20 !important;
        margin-bottom: 4px !important;

        &.table > :not(:first-child) {
            border-top: none;
        }

        &.table > :not(caption) > * > th {
            background-color: $mf-color-night-sky-5 !important;
        }

        &.table > :not(caption) > * > th,
        &.table > :not(caption) > * > td {
            &:first-child {
                padding-left: 20px !important;
            }

            &:last-child {
                padding-right: 20px !important;
            }
        }
    }
}

// NAV / TAB
.nav {
    &.nav-pills {
        gap: 16px !important;
        @include typography-headline-6;
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $border-color-light;



        .nav-link {
            &:not(.active) {
                color: $mf-color-night-sky-100; // tweak to override the usage of the bootstrap variable

                &:hover {
                    background-color: $mf-color-deep-blue-10;
                }
            }
        }

        &-no-border {
            margin-bottom: 0;
            border-bottom: none;
        }

        &-alternate {
            .nav-link {
                color: $mf-color-night-sky-100 !important;

                &.active {
                    background-color: $mf-color-deep-blue-20 !important;
                }
            }

        }
    }
}

.nav-link:focus-visible {
    outline: none;
}

// NG-BOOTSTRAP
ngb-tooltip-window {
    pointer-events: none !important;
    max-width: calc(100% - 16px) !important;

    * {
        text-align: start !important;
    }

    .tooltip-arrow {
        display: none !important;
    }

    .tooltip-inner {
        pointer-events: auto !important;
        border: 1px solid $mf-color-night-sky-20;
        @include box-shadow($strong-shadow);

        p {
            margin-bottom: 0;
            @include typography-body-3;
        }
    }

    @include media-breakpoint-up(sm) {
        max-width: map-get($grid-breakpoints, sm) !important;
    }
}

// COLORS
.bg-primary-dark {
    background-color: $mf-color-night-sky-100;
}

// CAROUSEL

ngb-carousel:focus-visible {
    outline: none;
}

// INPUT-GROUP

.input-group {

    > .form-control:focus,
    > .form-select:focus,
    > .form-floating:focus-within {
        z-index: 2;
    }
}

// BADGES

.badge {
    color: $mf-color-night-sky-100;

    .name {
        @include typography-body-2;
    }
}

// PROGRESS BAR

.progress {
    height: 16px;
    margin: inherit !important;
    border-radius: $mf-border-radius-20;
    background-color: $mf-color-night-sky-20;

    .progress-bar {
        span {
            padding: 0 16px;
            color: $mf-color-white;
        }
    }
}

// ACCORDION

.accordion {
    .accordion-item {
        .accordion-header {
            margin-bottom: 4px;
        }

        .property-row {
            border-bottom: none;
        }
    }
}

// TIMEPICKER
ngb-timepicker {
    .ngb-tp {
        display: grid;
        grid-template-columns: repeat(2, 64px);
        gap: 6px;

        .ngb-tp-hour,
        .ngb-tp-minute,
        .ngb-tp-second,
        .ngb-tp-meridian {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            order: 1;
            //Override for inherit all ngb flex classes
            display: inherit;
            flex-direction: inherit;
            align-items: inherit;
            justify-content: inherit;

            .btn {
                width: fit-content;
                font-size: 10px;
                padding: 0 !important;
                position: relative;
                top: 8px;
                left: -20px;
                grid-area: 2 / 2 / 1 / 2;

                &:first-child {
                    top: -6px;
                }
            }

            input {
                width: 64px;
                padding: 5px 10px !important;
                text-align: left;
            }
        }

        .ngb-tp-spacer {
            display: none;
        }
    }

    &.with-seconds {
        .ngb-tp {
            grid-template-columns: repeat(3, 64px);
        }
    }
}