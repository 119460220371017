﻿@import "../variables";
@import "../shared/borders";

hubshare-app {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    nk-loading-main-content {
        @extend %centered;
    }
}

* {
    font-variant-ligatures: none;
}

hr {
    &.invisible {
        border-top: none;
    }
}

.not-selectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: default;
}

/**
    TYPOGRAPHY
*/

p {
    @include typography-body-2;
}

.h1 {
    @include typography-headline-1;
}

.h2 {
    @include typography-headline-2;
}

.h3 {
    @include typography-headline-3;
}

.h4 {
    @include typography-headline-4;
}

.h5 {
    @include typography-headline-5;
}

.h6 {
    @include typography-headline-6;
}

/**
    MARGIN
*/
.no-margin {
    margin: 0 !important;
}

.no-min-width {
    min-width: fit-content;
}


$spaceamounts: (
    0,
    15,
    50,
    90
);

@each $space in $spaceamounts {
    .margin-bottom-#{$space} {
        margin-bottom: #{$space}px;
    }

    .margin-top-#{$space} {
        margin-top: #{$space}px;
    }
}

.overflow-visible {
    overflow: visible !important;
}


/**
    DISPLAY
*/
@include media-breakpoint-down(sm) {
    .mobile-hidden {
        display: none !important;
    }
}

@include media-breakpoint-down(md) {
    .tablet-hidden {
        display: none !important;
    }
}

.container:last-child {
    margin-bottom: 20px;
}

// MODULE
module {
    & > .container-fluid {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
}

// TINYMCE
.tox {
    .tox-dialog-wrap__backdrop {
        background-color: $modal-backdrop-bg !important;
        opacity: $modal-backdrop-opacity !important;
    }

    &.tox-tinymce-aux {
        z-index: $modal-zindex;
    }
}

.tox .tox-collection__item:not(.tox-collection__item--state-disabled) .tox-collection__item-label {
    line-height: 1 !important;
}

// LOADING

.loading {
    &__relative {
        position: relative;
        text-align: center;
        margin: auto;
    }

    &__absolute {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

nk-icon {
    .icon {
        &.#{$icon-prefix}close {
            cursor: pointer;
        }
    }
}

label {
    .required {
        color: $mf-color-alert-red;
        margin-left: 4px;
    }
}

.pagination-entries {
    padding-inline: 8px;
    height: 34px;
    border: 1px solid $mf-color-night-sky-20;
    background-color: $mf-color-night-sky-5;
    color: $mf-color-night-sky-60;
    display: flex;
    align-items: center;
    gap: 4px;
    @include typography-body-3;
    @include border-radius-8;

    & > * {
        margin: auto;
    }
}