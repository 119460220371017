@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "bootstrap/theme";
@import "variables";
@import "shared/borders";

@mixin button-outline-variant($color, $color-hover: color-contrast($color), $active-background: $color, $active-border: $color, $active-color: color-contrast($active-background), $border-color: $color, $border-focus-color: transparent) {
    color: $color;
    border-color: $border-color;

    &:hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;
    }

    .btn-check:focus + &,
    &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
        border-color: $border-focus-color;
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active,
    &.dropdown-toggle.show {
        color: $active-color;
        background-color: $active-background;
        border-color: $active-border;

        &:focus {
            @if $enable-shadows {
                @include box-shadow(0 0 0 $btn-focus-width rgba($color, .5));
            }

            @else {
                // Avoid using mixin so we can pass custom focus shadow properly
                box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
            }
        }
    }

    &:disabled,
    &.disabled {
        color: $color;
        background-color: transparent;
    }
}

@mixin dropdown-item-padding($padding: .25rem .5rem) {
    padding: $padding;
}

@mixin input-number($padding: .15rem .5rem, $width: 3.5rem) {
    &:is(nk-input) {
        .input-group {
            display: block;
        }
    }

    input[type=number] {
        padding: $padding !important;
        width: $width !important;
    }
}


@mixin transition($type: all, $speed: 0.2, $kind: ease) {
    & {
        transition: $type #{$speed}s $kind;
    }
}

@mixin rotate($degree: 180) {
    -webkit-transform: rotate(#{$degree}deg);
    /* Chrome and other webkit browsers */
    -moz-transform: rotate(#{$degree}deg);
    /* FF */
    -o-transform: rotate(#{$degree}deg);
    /* Opera */
    -ms-transform: rotate(#{$degree}deg);
    /* IE9 */
    transform: rotate(#{$degree}deg);
    /* W3C compliant browsers */
}



@mixin max-image-size($width: 16px, $height: 16px) {
    max-width: $width;
    max-height: $height;
}

@mixin min-image-size($width: 16px, $height: 16px) {
    min-width: $width;
    min-height: $height;
}

@mixin hub-panel() {
    & {
        border: none;
        @include border-radius-20;
        @include box-shadow(0px 4px 6px 1px rgba(0, 0, 0, .06));
    }
}

@mixin input-underline($color: #454545) {
    border: none;
    outline: none;
    box-shadow: none;
    margin: 0;
    border-bottom: 1px solid $color;
    border-radius: 0;
}

@mixin background-opacity($color: white, $opacity: 1) {
    background: $color;
    background-color: rgba($color, $opacity);
}


// grayscale      ex: filter: grayscale(100%);
// sepia          ex: filter: sepia(100%);
// saturate       ex: filter: saturate(0%);
// hue-rotate     ex: filter: hue-rotate(45deg);
// invert         ex: filter: invert(100%);
// brightness     ex: filter: brightness(15%);
// contrast       ex: filter: contrast(200%);
// blur           ex: filter: blur(2px);
@mixin filter($filter-type, $filter-amount) {
    filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin tr-list-hover() {
    &:hover {
        td {
            background-color: $color-elem-background-hover;
            color: $color-elem-text-hover;
            border: $color-elem-border-hover;
        }
    }
}

//--------- Animation -------------
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @-ms-keyframes #{$animation-name} {
        @content;
    }

    @-o-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

// Changing
@mixin animation-changing($color-start, $color-end) {
    animation-name: changing;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;

    @keyframes changing {
        0% {
            background-color: $color-start;
        }

        50% {
            background-color: $color-end;
        }

        100% {
            background-color: $color-start;
        }
    }
}

@mixin animation($str) {
    & {
        animation: #{$str};
    }
}

@keyframes icon-pulse-grow {
    100% {
        transform: scale(1.3);
    }
}

@mixin icon-pulse-grow {

    &:hover .icon,
    &:focus .icon,
    &:active .icon {
        animation-name: icon-pulse-grow;
        animation-duration: 0.3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }

    > .icon {
        transform: translateZ(0);
        transition-timing-function: ease-out;
    }
}

@mixin animated-underline($height: 4px, $color: $primary) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        background: $color;
        height: $height;
        transition-property: right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
    }

    &:hover:before,
    &:focus:before,
    &:active:before {
        right: 0;
    }
}

@mixin pulse($color: $primary) {
    & {
        background-color: $color;
        box-shadow: 0 0 0 0 $color;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba($color, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba($color, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba($color, 0);
        }
    }
}

/*
    Animate-transform, required some properties:

    $duration = 'animation-duration'
    $ease = 'animation-timing-function'
    $from = transform: 'tranform css properties'
    $to = transform: 'tranform css properties'
    $delay = 'animation-delay'
    $iteration = 'animation-iteration-count'
    $direction = 'animation-direction'

    EXEMPLE: 
    .exemple{
        ...
        @include animate-transform($duration: 900ms, cubic-bezier(0.680, -0.550, 0.265, 1.550), $from: scale(0.9), $to: scale(1.0));
    }
*/
@mixin animate-transform($duration, $ease, $from, $to, $delay: 0, $iteration: 1, $direction: normal) {
    animation-name: animate-transform;
    animation-duration: #{$duration};
    animation-timing-function: #{$ease};
    animation-delay: #{$delay};
    animation-iteration-count: #{$iteration};
    animation-direction: #{$direction};

    @keyframes animate-transform {
        0% {
            transform: #{$from};
        }

        100% {
            transform: #{$to};
        }
    }
}

@mixin active-border($border-left: false, $border-right: false) {
    @if $border-left {
        border-left: #{$border-left};
    }

    @if $border-right {
        border-right: #{$border-right};
    }
}


@mixin border-radii($top-left: $border-radius, $top-right: $border-radius, $bottom-right: $border-radius, $bottom-left: $border-radius) {
    & {
        border-top-left-radius: $top-left;
        border-top-right-radius: $top-right;
        border-bottom-right-radius: $bottom-right;
        border-bottom-left-radius: $bottom-left;
        -webkit-border-top-left-radius: $top-left;
        -webkit-border-top-right-radius: $top-right;
        -webkit-border-bottom-right-radius: $bottom-right;
        -webkit-border-bottom-left-radius: $bottom-left;
        -moz-border-radius-topleft: $top-left;
        -moz-border-radius-topright: $top-right;
        -moz-border-radius-bottomright: $bottom-right;
        -moz-border-radius-bottomleft: $bottom-left;
    }
}

@mixin background-striped($deg: -45deg, $color-stripe: #f9f9f9, $color-stripe-width: 10px, $color-empty: white, $color-empty-width: 20px) {
    & {
        background: repeating-linear-gradient($deg, $color-stripe, $color-stripe $color-stripe-width, $color-empty $color-stripe-width, $color-empty $color-empty-width);
        @include transition($speed: 1s);
    }
}

@mixin ellipsis() {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncate($width: 250px) {
    display: inline-block;
    width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin modern-scrollbar($track: $mf-color-night-sky-10, $thumb: $mf-color-night-sky-100, $size: $scrollbar-width) {
    & {
        animation: expanded-scroll .25s;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        overflow-x: auto;
        scrollbar-width: thin;
        scrollbar-color: $thumb $track;
        @include transition;
    }

    @keyframes expanded-scroll {
        0% {
            overflow-y: hidden;
            overflow-x: hidden;
        }

        100% {
            overflow-y: auto;
            overflow-x: auto;
        }
    }
}

@mixin blink-background($color-base: white, $color-blink: #eee, $duration: 1s, $iteration: infinite, $ease: ease-in-out) {
    background-color: $color-base;
    animation: blink-background;
    animation-duration: $duration;
    animation-iteration-count: $iteration;
    animation-timing-function: $ease;

    @-webkit-keyframes blink-background {
        50% {
            background-color: $color-blink;
        }
    }

    @keyframes blink-background {
        50% {
            background-color: $color-blink;
        }
    }
}

@mixin hub-status {
    &.active {
        background-color: $mf-color-hub-active;
    }

    &.inactive {
        background-color: $mf-color-hub-inactive;
    }

    &.design {
        background-color: $mf-color-hub-design;
    }

    &.wizard {
        background-color: $mf-color-hub-wizard;
    }
}

/*** FUNCTIONS ***/

@mixin ie-detect {
    // IE10 and above
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin counter($position: absolute, $background: $mf-color-alert-red, $color: $mf-color-white, $top: 0, $right: 0) {
    position: $position;
    top: $top;
    right: $right;
    background: $background;
    font-weight: $font-weight-semibold;
    color: $color;
    height: 20px;
    min-width: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 11px;
    @include border-radius-16;
}

/* UNITE :  allow several classes to be glued to avoid repetition */

@function unite($list, $glue: ", ") {
    @if length($list)==1 {
        @return $list;
    }

    @else {
        $string: "";

        @each $item in $list {
            @if $string !="" {
                $string: $string + $glue;
            }

            $string: $string + $item;
        }

        @return $string;
    }
}

%centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%scrollable {
    overflow: scroll;
    @include modern-scrollbar($track: transparent, $thumb: transparent);

    &:hover {
        @include modern-scrollbar();
    }
}
