$mf-font-family-primary: "Open Sans";
$mf-font-family-secondary: "Figtree";

$hub-font: $mf-font-family-primary;
$hub-font-weight: 400;
$hub-font-title: $mf-font-family-secondary;
$hub-font-content: $hub-font;

// Heading H1
@mixin typography-headline-1 {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 60px;
        font-weight: 400;
        line-height: 72px;
    }
}

// Heading H2
@mixin typography-headline-2 {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 48px;
        font-weight: 400;
        line-height: 60px;
    }
}

// Heading H3
@mixin typography-headline-3 {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 34px;
        font-weight: 400;
        line-height: 40px;
    }
}

// Heading H4 and widget heading
@mixin typography-headline-4 {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
    }
}

// Heading H5
@mixin typography-headline-5 {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
}

// Heading H6
@mixin typography-headline-6 {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}

// Table headings
@mixin typography-headline-7 {
    & {
        font-family: $mf-font-family-primary;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
}

// Large text
@mixin typography-body-1 {
    & {
        font-family: $mf-font-family-primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}

// Main base font
@mixin typography-body-2 {
    & {
        font-family: $mf-font-family-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
}

// Tooltips
@mixin typography-body-3 {
    & {
        font-family: $mf-font-family-primary;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
}

// Buttons
@mixin typography-button {
    & {
        font-family: $mf-font-family-secondary;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
}
