$icomoon-font-family: "hubshare-icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-deselect: "\e9cb";
$icon-export: "\e9c9";
$icon-import: "\e9ca";
$icon-draggable: "\e9c8";
$icon-notification-info: "\e9c7";
$icon-sso: "\e9c6";
$icon-reminder: "\e9bf";
$icon-circle-check-empty-checked: "\e9be";
$icon-task: "\e9c1";
$icon-circle-check-empty: "\ea56";
$icon-circle-check: "\e95f";
$icon-cabinet: "\e617";
$icon-certificate: "\e997";
$icon-sign: "\e95a";
$icon-edit: "\e95a";
$icon-spinner: "\e968";
$icon-spinner-5: "\e968";
$icon-loading: "\e968";
$icon-paperplane-send: "\e9c4";
$icon-selection: "\e9c2";
$icon-kanban: "\e9bb";
$icon-gantt: "\e9bc";
$icon-table: "\e9bd";
$icon-checkbox-unchecked: "\ea53";
$icon-external-link: "\e902";
$icon-link-out: "\e902";
$icon-remove: "\e903";
$icon-bin: "\e903";
$icon-trash: "\e903";
$icon-loop: "\e905";
$icon-cycle: "\e905";
$icon-new: "\e998";
$icon-pause: "\e906";
$icon-pause-circle-empty: "\e906";
$icon-play: "\e907";
$icon-play-circle: "\e907";
$icon-play-circle-empty: "\e907";
$icon-previous: "\e908";
$icon-stop: "\e909";
$icon-film: "\e90a";
$icon-camera-movie: "\e90b";
$icon-bubble: "\e90c";
$icon-location: "\e90d";
$icon-bubble-empty: "\e90e";
$icon-topic: "\e90e";
$icon-phone: "\e90f";
$icon-voicemail: "\e999";
$icon-mail: "\e99a";
$icon-rss: "\e99b";
$icon-add: "\e99c";
$icon-plus: "\e99c";
$icon-add-box-full: "\e99d";
$icon-plus-square: "\e99d";
$icon-add-circle-full: "\e910";
$icon-circle-plus: "\e910";
$icon-plus-round: "\e910";
$icon-add-circle-empty: "\e900";
$icon-box-add: "\e911";
$icon-close: "\e912";
$icon-cross: "\e912";
$icon-copy: "\e913";
$icon-scissors: "\e914";
$icon-task-folder: "\e915";
$icon-pencil: "\e916";
$icon-flag: "\e917";
$icon-remove-circle: "\e918";
$icon-circle-minus: "\e918";
$icon-remove-circle-empty: "\e99e";
$icon-reply: "\e919";
$icon-reply-all: "\e91a";
$icon-box-remove: "\e91b";
$icon-flag-empty: "\e91c";
$icon-alarms: "\e91e";
$icon-clock-empty: "\e91f";
$icon-airplane: "\e920";
$icon-battery: "\e921";
$icon-coin: "\e922";
$icon-money: "\e922";
$icon-edit-full: "\e923";
$icon-indent-decrease: "\e924";
$icon-indent-increase: "\e925";
$icon-list: "\e926";
$icon-list-number: "\e927";
$icon-calendar-empty: "\e99f";
$icon-image: "\e928";
$icon-picture: "\e928";
$icon-graph: "\e9a0";
$icon-cloud: "\e92a";
$icon-cloud-download: "\e92b";
$icon-download: "\e92c";
$icon-upload: "\e92d";
$icon-upload-flat: "\e92d";
$icon-folder: "\e92e";
$icon-folder-user: "\e92f";
$icon-folder-plus: "\e930";
$icon-keyboard: "\e931";
$icon-arrow-down-bold: "\e932";
$icon-arrow-left-bold: "\e933";
$icon-arrow-right-bold: "\e934";
$icon-arrow-up-bold: "\e935";
$icon-mobile: "\e936";
$icon-security: "\e9a1";
$icon-tablet: "\e937";
$icon-screen: "\e938";
$icon-camera: "\e939";
$icon-images: "\e93a";
$icon-pictures: "\e93a";
$icon-colors: "\e9a2";
$icon-eye: "\e93b";
$icon-timer: "\e93c";
$icon-stopwatch: "\e93c";
$icon-brightness: "\e93d";
$icon-cart: "\e93e";
$icon-printer: "\e93f";
$icon-cutlery: "\e940";
$icon-bubble-edit: "\e9a3";
$icon-restaurant: "\e9a4";
$icon-apps: "\e9a5";
$icon-arrow-left: "\e941";
$icon-triangle-down: "\e942";
$icon-caret-down: "\e942";
$icon-triangle-up: "\e943";
$icon-caret-up: "\e943";
$icon-arrow-right: "\e944";
$icon-cancel: "\e945";
$icon-checkmark: "\e946";
$icon-check: "\e946";
$icon-expand-large: "\e947";
$icon-fullscreen: "\e947";
$icon-shrink: "\e948";
$icon-fullscreen-exit: "\e948";
$icon-menu: "\e949";
$icon-navicon: "\e949";
$icon-refresh: "\e94a";
$icon-spinner-4: "\e94a";
$icon-triangle-left: "\e94b";
$icon-caret-left: "\e94b";
$icon-triangle-right: "\e94c";
$icon-caret-right: "\e94c";
$icon-calendar-check: "\e9a6";
$icon-calendar-times: "\e9a7";
$icon-bubble-alert: "\e94d";
$icon-bubble-video: "\e94e";
$icon-cake: "\e9a8";
$icon-bell: "\e9a9";
$icon-bell-on: "\e9aa";
$icon-users: "\e94f";
$icon-users-empty: "\e94f";
$icon-users-double: "\e94f";
$icon-user: "\e901";
$icon-user-empty: "\e950";
$icon-share: "\e9ab";
$icon-check-box: "\e9ac";
$icon-star: "\e951";
$icon-star-o: "\e952";
$icon-monument: "\e954";
$icon-assignment-late: "\e9ad";
$icon-bookmark: "\e959";
$icon-bug: "\e95b";
$icon-code: "\e9ae";
$icon-creditcard: "\e95c";
$icon-credit-card: "\e95c";
$icon-credit: "\e95c";
$icon-heart: "\e95d";
$icon-heart-empty: "\e95e";
$icon-history: "\e9b0";
$icon-lock: "\e9b1";
$icon-info: "\e960";
$icon-info-circle: "\e960";
$icon-info-circle-o: "\e961";
$icon-info-circle-empty: "\e961";
$icon-open: "\e9b2";
$icon-unlocked: "\e9b3";
$icon-lock-open: "\e9b3";
$icon-switch: "\e962";
$icon-search: "\e963";
$icon-unlike: "\e9b4";
$icon-like: "\e9b5";
$icon-shield-check: "\e965";
$icon-eye-blocked: "\e966";
$icon-portfolio: "\e967";
$icon-briefcase: "\e967";
$icon-zoom-in: "\e969";
$icon-zoom-out: "\e96a";
$icon-gif: "\e96b";
$icon-lightbulb: "\e96c";
$icon-calendar-range: "\e96d";
$icon-stats: "\e96e";
$icon-update: "\e96f";
$icon-clock: "\e970";
$icon-calendar: "\e971";
$icon-filter: "\e972";
$icon-bookmarks: "\e973";
$icon-folder-upload: "\e974";
$icon-project: "\e964";
$icon-exit: "\e975";
$icon-logout: "\e975";
$icon-shield: "\e977";
$icon-speed: "\e978";
$icon-address-book: "\e979";
$icon-bolt: "\e97a";
$icon-lightning: "\e97a";
$icon-trophy: "\e9b8";
$icon-anchor: "\e97b";
$icon-arrow-down-circle: "\e97c";
$icon-arrow-up-circle: "\e97d";
$icon-dashboard: "\e9b9";
$icon-wand: "\e97e";
$icon-blocked: "\e97f";
$icon-calculate: "\e980";
$icon-campaign: "\e9ba";
$icon-circle: "\e9af";
$icon-bubble-mark: "\e981";
$icon-wrench: "\e982";
$icon-tools: "\e982";
$icon-enter: "\e983";
$icon-login: "\e983";
$icon-bubble-check: "\e984";
$icon-bubble-unread: "\e985";
$icon-user-add-empty: "\e986";
$icon-user-add: "\e987";
$icon-user-plus: "\e987";
$icon-pushpin: "\e988";
$icon-qrcode: "\e989";
$icon-support: "\e98a";
$icon-add_task: "\e98b";
$icon-circle-cross: "\e043";
$icon-grid: "\e102";
$icon-th-large: "\e102";
$icon-move: "\e11c";
$icon-square-cross: "\e048";
$icon-vcard: "\e9c3";
$icon-archive: "\e87b";
$icon-box: "\e87b";
$icon-browser: "\e869";
$icon-database: "\e877";
$icon-disk-card: "\e8b7";
$icon-disk: "\e8b7";
$icon-ellipsis-v: "\e8fa";
$icon-ellipsis-h: "\e98c";
$icon-flow-branch: "\e8cd";
$icon-flow-cascade: "\e9ea";
$icon-flow-line: "\e8cf";
$icon-flow-parallel: "\e9ee";
$icon-tree: "\e9ec";
$icon-flow-tree: "\e9ec";
$icon-hourglass: "\e859";
$icon-busy: "\e859";
$icon-home: "\e835";
$icon-house: "\e835";
$icon-language: "\e85b";
$icon-leaf: "\e845";
$icon-link: "\e83b";
$icon-hyperlink: "\e83b";
$icon-minus: "\e88c";
$icon-line: "\e892";
$icon-music-double: "\e847";
$icon-attachment: "\e81b";
$icon-paperplane: "\e818";
$icon-pie: "\e953";
$icon-pie-chart: "\e953";
$icon-popup: "\e836";
$icon-question: "\e9b7";
$icon-retweet: "\e9c0";
$icon-rocket: "\e863";
$icon-signal: "\e87e";
$icon-connection: "\e87e";
$icon-ticket: "\e87c";
$icon-vcard-empty: "\e823";
$icon-hub-icon-large: "\e91d";
$icon-hub-logo: "\e91d";
$icon-transfer: "\e9c5";
$icon-admin: "\e976";
$icon-barcode: "\eacb";
$icon-binoculars: "\eb13";
$icon-bullhorn: "\eab4";
$icon-megaphone: "\eab4";
$icon-circle-down: "\e956";
$icon-down: "\e956";
$icon-circle-bottom: "\e956";
$icon-circle-full: "\e810";
$icon-circle-bullet: "\e810";
$icon-circle-left: "\e955";
$icon-left: "\e955";
$icon-circle-previous: "\e955";
$icon-arrow-circle-left: "\e955";
$icon-circle-right: "\e957";
$icon-right: "\e957";
$icon-circle-next: "\e957";
$icon-arrow-circle-right: "\e957";
$icon-circle-up: "\e958";
$icon-up: "\e958";
$icon-circle-top: "\e958";
$icon-clipboard: "\e904";
$icon-cog: "\eb22";
$icon-compass: "\e644";
$icon-console: "\e75d";
$icon-earth: "\e6c2";
$icon-equalizer: "\eb21";
$icon-file-excel: "\e7b5";
$icon-file-powerpoint: "\e7b7";
$icon-file-xml: "\e7b8";
$icon-file-zip: "\e7b6";
$icon-zip: "\e7b6";
$icon-file: "\eabf";
$icon-forward: "\eafe";
$icon-send: "\eafe";
$icon-key: "\e98d";
$icon-library: "\eab9";
$icon-linkedin: "\e79f";
$icon-map: "\e646";
$icon-map-empty: "\e646";
$icon-newspaper: "\e604";
$icon-notebook: "\e63f";
$icon-office: "\e603";
$icon-paste: "\e62a";
$icon-paste-full: "\eac4";
$icon-pen: "\e608";
$icon-podcast: "\eab6";
$icon-point-right: "\eb8c";
$icon-point-up: "\e6f0";
$icon-profile: "\eabc";
$icon-quill: "\eaa1";
$icon-quote: "\eb0c";
$icon-settings: "\eb4d";
$icon-spam: "\e6fc";
$icon-stack: "\e62d";
$icon-stop-circle: "\e707";
$icon-storage: "\eaf8";
$icon-subtract: "\e7d1";
$icon-tag: "\e630";
$icon-volume-decrease: "\e71a";
$icon-volume-empty: "\ebb2";
$icon-volume-high: "\e714";
$icon-volume-increase: "\e719";
$icon-volume-low: "\ebb1";
$icon-volume-medium: "\ebb0";
$icon-volume-mute: "\ebb3";
$icon-asterisk: "\e995";
$icon-book: "\f02d";
$icon-cubes: "\e996";
$icon-cursor: "\e9b6";
$icon-warning: "\e929";
$icon-exclamation: "\e929";
$icon-exclamation-triangle: "\e929";
$icon-notification: "\e929";
$icon-help: "\f059";
$icon-sort: "\e98e";
$icon-sort-alpha-asc: "\e98f";
$icon-sort-alpha-desc: "\e990";
$icon-sort-amount-asc: "\e991";
$icon-sort-amount-desc: "\e994";
$icon-sort-numeric-asc: "\e992";
$icon-sort-numeric-desc: "\e993";

