﻿@use "sass:math";
@import "../import";
@import "../shared/typography";

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    font-family: $hub-font; //Arial Narrow, "Arial", "Arial Black", "Arial Bold", "Arial Bold Italic", "Arial Italic", "Arial Narrow Bold", "Arial Narrow Bold Italic", "Arial Narrow Italic", "Sans-serif";
    @include modern-scrollbar();
    scroll-behavior: smooth;
}

html.ng-app-root {
    body {
        position: relative;

        &.hubshare-app-container {
            overflow: initial !important;
        }

        #hub-body {
            position: relative;
            top: $hub-top-bar-height;
            height: calc(100% - #{$hub-top-bar-height});

            hub-side-bar {
                width: $hub-side-bar-width-expanded;
                transition: width linear math.div($hub-delay-animation, 4);
                height: auto;

                &.side-bar-collapsed {
                    width: $hub-side-bar-width-collapsed;
                }

                &.side-bar-expended {
                    width: $hub-side-bar-width-expanded;
                }
            }
        }
    }
}

@media print {
    .hide-print {
        display: none !important;
    }

    .printable {
        display: block !important;
    }
}
