/* open-sans-latin-300-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(./files/open-sans-latin-300-normal.woff2) format('woff2'), url(./files/open-sans-latin-300-normal.woff) format('woff');
}

/* open-sans-latin-400-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(./files/open-sans-latin-400-normal.woff2) format('woff2'), url(./files/open-sans-latin-400-normal.woff) format('woff');
}

/* open-sans-latin-500-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(./files/open-sans-latin-500-normal.woff2) format('woff2'), url(./files/open-sans-latin-500-normal.woff) format('woff');
}

/* open-sans-latin-600-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url(./files/open-sans-latin-600-normal.woff2) format('woff2'), url(./files/open-sans-latin-600-normal.woff) format('woff');
}

/* open-sans-latin-700-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(./files/open-sans-latin-700-normal.woff2) format('woff2'), url(./files/open-sans-latin-700-normal.woff) format('woff');
}

/* open-sans-latin-800-normal */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url(./files/open-sans-latin-800-normal.woff2) format('woff2'), url(./files/open-sans-latin-800-normal.woff) format('woff');
}