// import fonts from @fontsource npm package

@import "@fontsource/open-sans/latin.css";
@import "@fontsource/open-sans/latin-italic.css";
@import "@fontsource/open-sans/latin-ext-400.css";
@import "@fontsource/open-sans/latin-ext-400-italic.css";
@import "@fontsource/open-sans/latin-ext-600.css";
@import "@fontsource/open-sans/latin-ext-600-italic.css";
@import "@fontsource/figtree/latin.css";
@import "@fontsource/figtree/latin-ext-400.css";
@import "@fontsource/figtree/latin-ext-400-italic.css";