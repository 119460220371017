﻿@import "../shared/colors";
@import "../shared/shadows";
@import "../shared/typography";
@import "../shared/borders";

// DEFAULTS
// Line height
$font-size-default-value: 14;
$line-height-default-value: 20;
$default-line-height: calc($line-height-default-value / $font-size-default-value);

// CONFIGURATION
$enable-shadows: false;
$enable-rounded: true;
$enable-cssgrid: true;
$enable-dark-mode: false;

// THEME
// COLORS
$primary: $mf-color-blue-moment-100;
$secondary: $mf-color-deep-blue-20;
$success: $mf-color-alert-green;
$danger: $mf-color-alert-red;
$warning: $mf-color-alert-yellow;
$info: $mf-color-alert-blue;
$light: $mf-color-white;
$dark: $mf-color-dark;
$highlight: $mf-color-alert-highlight-yellow;

// Border radius
$border-radius: $mf-border-radius-8;

// Specific colors
$body-color: $mf-color-night-sky-100;
$body-secondary-color: $mf-color-night-sky-60;

$border-color: $mf-color-night-sky-40;
// END COLORS

// FONTS
$font-family-base: $mf-font-family-primary;
$font-size-base: .875rem; // 14px
$line-height-base: $default-line-height; // should be unitless and be the result of the line height in px divided by the font size in px.
$font-family-sans-serif: $mf-font-family-primary,
// Cross-platform generic font family (default user interface font)
system-ui,
// Safari for macOS and iOS (San Francisco)
-apple-system,
// Windows
"Segoe UI",
// Android
Roboto,
// Basic web fallback
"Helvetica Neue", Arial,
// Linux
"Noto Sans", "Liberation Sans",
// Sans serif fallback
sans-serif,
// Emoji fonts
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-margin-bottom: 0;

// GRID
$grid-gutter-width: 16px;

// LINKS
$link-color: $mf-color-blue-moment-100-link;
$link-hover-color: $link-color;
$link-decoration: none;
$link-hover-decoration: null;
$stretched-link-pseudo-element: after;
$stretched-link-z-index: 1;

// MODAL
$modal-xxl: 80%;

// SHADOWS
// disable shadows on buttons and forms elements
$input-box-shadow: none;
$input-focus-box-shadow: null;

$btn-box-shadow: null;
$btn-focus-box-shadow: null;
$btn-active-box-shadow: null;

$form-select-box-shadow: null;
$form-select-focus-box-shadow: null;

// Disable focus outline
$input-btn-focus-width: 0;
$focus-ring-opacity: 0;


// GUTTERS
$spacer-default: 1rem;
$spacers: ( 0: 0, 1: $spacer-default * .25, 2: $spacer-default * .5, 3: $spacer-default, 4: $spacer-default * 1.5, 5: $spacer-default * 3, 6: $spacer-default * 4, 7: $spacer-default * 5, 8: $spacer-default * 6, );


// BUTTONS
$btn-font-family: $mf-font-family-secondary;
$btn-font-size: .875rem; // 14px

// FORMS
$form-label-margin-bottom: 0;

// POPOVER
$popover-border-color: $mf-color-night-sky-20;
$popover-arrow-outer-color: transparent;
$popover-border-radius: 8px;
$popover-body-padding-y: 16px;
$popover-body-padding-x: 16px;
$popover-box-shadow: $strong-shadow;
$popover-max-width: auto;
$popover-font-size: inherit;

// TABLES
$table-striped-bg-factor: .035;
$table-border-color: $mf-color-night-sky-20;
$table-hover-bg-factor: 1;
$table-hover-bg: $mf-color-deep-blue-5;
$table-color: $mf-color-night-sky-100;
$table-cell-padding-y: 12px;
$table-cell-padding-x: 12px;

// OFFCANVAS
$zindex-offcanvas-backdrop: 1050;
$zindex-offcanvas: 1055;

// Item row height
$item-row-height: 3.4375rem;

// TOOLTIP
$tooltip-bg: $mf-color-night-sky-5;
$tooltip-color: $mf-color-night-sky-100;
$tooltip-opacity: 1;
$tooltip-padding-y: $spacer-default * .5;
$tooltip-max-width: 576px; // grid SM size

// SCROLLBAR & ELEMENT WIDTH / MARGIN FOR SCROLLBAR DISPLAY
// Pure coincidence to have the same values here, please let them like that.
$scrollbar-width: 6px;
$scrollbar-offset: 6px;

// INPUTS AND FORMS
$input-border-radius: 8px;
$input-padding-y: 5px;
$input-padding-x: 12px;
$form-label-margin-bottom: 8px;
$input-font-size: 14px;
$input-placeholder-color: $mf-color-night-sky-60;
$input-color: $mf-color-night-sky-100;
$input-focus-border-color: $mf-color-night-sky-100;
$input-disabled-color: $mf-color-night-sky-40;
$input-disabled-border-color: $mf-color-night-sky-40;
$input-disabled-bg: $mf-color-night-sky-5;

$form-check-input-border-radius: 2px;
$form-check-input-border: 1px solid $mf-color-night-sky-100;

$form-switch-width: 2.143em; // 30px (BS only allows "em" unit)
$form-switch-color: $mf-color-night-sky-100;
$form-switch-checked-color: $mf-color-white;

// PROGRESS BAR
$progress-border-radius: 4px;
$progress-box-shadow: none;
$progress-bar-bg: $mf-color-night-sky-5;

// NAV TABS
$nav-tabs-border-color: $mf-color-night-sky-20;
$nav-tabs-link-active-border-color: $mf-color-night-sky-20 $mf-color-night-sky-20 $mf-color-white;
$nav-tabs-link-active-color: $mf-color-night-sky-100;

$nav-pills-border-radius: 8px;
$nav-pills-link-active-bg: $mf-color-night-sky-100;

// CARD
$card-border-color: $border-color;

// ACCORDION
$accordion-icon-color: $mf-color-night-sky-100;
$accordion-icon-active-color: $mf-color-night-sky-100;
$accordion-button-icon: url("data:image/svg+xml,<svg fill='#{$accordion-icon-color}' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M9.875 11.438l6.125 6.125 6.125-6.125 1.875 1.875-8 8-8-8z'></path></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg fill='#{$accordion-icon-active-color}' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path d='M9.875 11.438l6.125 6.125 6.125-6.125 1.875 1.875-8 8-8-8z'></path></svg>");
$accordion-button-bg: $mf-color-deep-blue-5;
$accordion-button-active-bg: $mf-color-blue-moment-5;
$accordion-border-width: 0;
$accordion-button-focus-border-color: transparent;
$accordion-border-radius: $mf-border-radius-8;

// DROPDOWN
$dropdown-border-color: $mf-color-night-sky-100;