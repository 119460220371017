﻿@import "../import";
@import "../shared/spacing";
@import "../shared/typography";
@import "../shared/borders";
@import "../shared/shadows";

@media (min-width: 768px) {
    .modal-xl {
        width: 80% !important;
    }
}


// Import / Export responsive
@include media-breakpoint-between(sm, lg) {
    ngb-modal-window {
        &.modal {
            &.import-export {
                .modal-dialog {
                    max-width: 90% !important;

                    .modal-xl {
                        width: 90% !important;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .modal-xxl {
        max-width: $modal-xxl;
        max-height: $modal-xxl;
    }
}
@include media-breakpoint-down(sm) {
    .modal-xxl {
        width: calc(100% - 16px);
    }
}

.modal-content {
    background-color: $mf-color-white;
    font-size: $font-size-base;
    @include border-radius-20;
    @include box-shadow($strong-shadow);
    @include transition;

    .close-button {
        color: $mf-color-night-sky-100;
        @include transition;
    }

    h1 {
        img {
            margin-right: 5px;
        }
    }
}

.modal {
    -webkit-overflow-scrolling: auto;

    & > .modal-dialog {
        @include ie-detect {
            transform: none;
        }

        & > .modal-content {
            .modal-header {
                padding: spacer(28) spacer(28) spacer(16) spacer(28);

                .modal-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 4px;
                    flex: 1 1 auto;
                    color: $mf-color-night-sky-100;
                    @include typography-headline-4;

                    .close-button {
                        cursor: pointer;
                    }
                }
            }

            @include media-breakpoint-down(lg) {
                .modal-header {
                    padding: spacer(16);
                }
            }

            .modal-body {
                padding: 0 spacer(28);
            }

            @include media-breakpoint-down(lg) {
                .modal-body {
                    padding: spacer(16);
                }
            }

            .modal-header, .modal-footer {
                border: none !important;
            }

            .modal-footer {
                padding: spacer(20) spacer(28) spacer(28) spacer(28);
                display: flex;
                gap: spacer(16);

                & > * {
                    margin: 0 !important;
                }
            }

            @include media-breakpoint-down(lg) {
                .modal-footer {
                    padding: spacer(16);
                }
            }
        }
    }
}

.modal, .modal-backdrop {
    @include modern-scrollbar();
}
