@import "colors";

.visualization-pie{
    color: $mf-color-icon-blue;
}

.visualization-doughnut{
    color: $mf-color-icon-cyan;
}

.visualization-polar{
    color: $mf-color-icon-orange;
}

.visualization-bar{
    color: $mf-color-icon-green;
}

.visualization-line{
    color: $mf-color-icon-yellow;
}

.visualization-radar{
    color: $mf-color-icon-purple;
}