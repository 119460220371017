$light-shadow: 4px 4px 10px 0px rgba(black, 0.10);
$strong-shadow: 0px 4px 4px 0px rgba(black, 0.25);
$widget-shadow: 0px 4px 4px 0px rgba(black, 0.1);

/// Apply shadow on a component
///
/// @param {variable || string} $shadow
@mixin box-shadow($shadow: $light-shadow) {
    & {
        box-shadow: $shadow;
        -webkit-box-shadow: $shadow;
        -moz-box-shadow: $shadow;
    }
}
