﻿@import "bootstrap/theme";

// Nk-icon prefix class
$icon-prefix: "mf-icon-";

/*Color element*/
// TODO [jb] see during the component refactoring if it can be removed.
$color-elem-background-hover: $table-hover-bg;
$color-elem-border-hover: $mf-color-night-sky-20;
$color-elem-text-hover: $mf-color-night-sky-60;

/*Table header*/
$table-header-background: $mf-color-night-sky-5;

/*Table row or columns selected color*/
$color-elem-background-selected: $mf-color-deep-blue-20;

/*Sidebar delay animation (second)*/
$hub-delay-animation: 1s;

/*Sidebar new hub*/
$hub-side-bar-width-collapsed: 68px;
$hub-side-bar-width-expanded: 260px;
$hub-side-bar-background-color: $mf-color-night-sky-100;
$hub-side-bar-text-color: $mf-color-white;

/*Top bar*/
$hub-top-bar-height: 68px;
$hub-top-bar-background-color: $mf-color-night-sky-100;
$hub-top-bar-icon-size: 32px;
$hub-side-bar-icon-size: 24px;
$hub-top-bar-text-color: $mf-color-white;

/* Toast */
$toast-width: 548px;
$toast-margin: 10px;

/* NkTag color */
$color-tag-default: $primary;
$color-tag-user: $primary;
$color-tag-task: $primary;
$color-tag-event: $warning;
$color-tag-resource: $success;
$color-tag-hub: $primary;

/* Modal side bar size for menu scoped to the task management */
$modal-sidebar-menu-collapsed-width: 3.3rem;
$modal-sidebar-menu-expanded-width: 12.5rem;

/* Wizard */
$wizard-header-height: 53px;

/* Margin */
$margin-default: 15px;

/* Modal z-index */
$modal-zindex: $zindex-offcanvas;

/* Border color light*/
$border-color-light: $mf-color-night-sky-20;