/* open-sans-latin-300-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url(./files/open-sans-latin-300-italic.woff2) format('woff2'), url(./files/open-sans-latin-300-italic.woff) format('woff');
}

/* open-sans-latin-400-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url(./files/open-sans-latin-400-italic.woff2) format('woff2'), url(./files/open-sans-latin-400-italic.woff) format('woff');
}

/* open-sans-latin-500-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url(./files/open-sans-latin-500-italic.woff2) format('woff2'), url(./files/open-sans-latin-500-italic.woff) format('woff');
}

/* open-sans-latin-600-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url(./files/open-sans-latin-600-italic.woff2) format('woff2'), url(./files/open-sans-latin-600-italic.woff) format('woff');
}

/* open-sans-latin-700-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url(./files/open-sans-latin-700-italic.woff2) format('woff2'), url(./files/open-sans-latin-700-italic.woff) format('woff');
}

/* open-sans-latin-800-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url(./files/open-sans-latin-800-italic.woff2) format('woff2'), url(./files/open-sans-latin-800-italic.woff) format('woff');
}