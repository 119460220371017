/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file */
@import "app/style/bootstrap";

@import "app/style/variables";
@import "app/style/mixins";

@import "app/style/bootstrap/override";
@import "app/style/bootstrap/utilities";

@import 'app/library/icomoon/style';
@import "app/style/fonts/fonts.min.g.css";

@import "app/style/shared";
