﻿///////////////////////////////////////////////////
// Common style shared accross the application   //
// to determine the flag (language) image styles //
///////////////////////////////////////////////////

.flag-small {
    width: 1rem;
}

.flag-medium {
    width: 1.5rem;
}

.flag-large {
    width: 3rem;
}

.nav-tabs {
    .nav-item {
        .nav-link {
            img[class^="flag"] {
                opacity: 0.35;
            }

            &.active {
                img[class^="flag"] {
                    opacity: 1;
                }
            }
        }
    }
}
