// Grays
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ( "100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900 );

// to Generate classes (bg and text) for the different colors provided by Bootstrap :
// https: //5balloons.info/generate-background-color-for-all-available-colors-in-bootstrap-5/

//Generating All Shades of Gray
@each $key, $value in $grays {
    .bg-gray-#{$key} {
        background-color: $value !important;
    }

    .text-gray-#{$key} {
        color: $value !important;
    }
}

// PZ_TD Remove above gray colors when they will be cleaned in each template / scss file. 

$mf-color-night-sky-100: #0a1541;
$mf-color-night-sky-80: #3b4467;
$mf-color-night-sky-60: #6c728d;
$mf-color-night-sky-40: #9da1b3;
$mf-color-night-sky-20: #ced0d9;
$mf-color-night-sky-10: #e6e8ec;
$mf-color-night-sky-5: #f3f3f6;

$mf-color-blue-moment-100: #006eef;
$mf-color-blue-moment-100-link: #0069e0;
$mf-color-blue-moment-80: #338bf2;
$mf-color-blue-moment-60: #66a8f5;
$mf-color-blue-moment-40: #99c5f9;
$mf-color-blue-moment-20: #cce2fc;
$mf-color-blue-moment-10: #e5f0fd;
$mf-color-blue-moment-5: #f2f8fe;

$mf-color-deep-blue-100: #001f88;
$mf-color-deep-blue-80: #334ca0;
$mf-color-deep-blue-60: #6679b8;
$mf-color-deep-blue-40: #99a5cf;
$mf-color-deep-blue-20: #ccd2e7;
$mf-color-deep-blue-10: #e5e9f3;
$mf-color-deep-blue-5: #f2f4f9;

$mf-color-evergreen-100: #0c685d;
$mf-color-evergreen-80: #3d867d;
$mf-color-evergreen-60: #6da49e;
$mf-color-evergreen-40: #9ec3be;
$mf-color-evergreen-20: #cee1df;
$mf-color-evergreen-10: #e7f0ef;
$mf-color-evergreen-5: #f3f8f7;

$mf-color-alert-green: #00ab47;
$mf-color-alert-yellow: #c28900;
$mf-color-alert-highlight-yellow: #ffffaf;
$mf-color-alert-red: #d50000;
$mf-color-alert-blue: #006eef;
$mf-color-dark: #000000;
$mf-color-white: #ffffff;

// Icon colors.
$mf-color-icon-blue: #006eef;
$mf-color-icon-cyan: #15a0c8;
$mf-color-icon-purple: #0d0d0e;
$mf-color-icon-green: $mf-color-alert-green;
$mf-color-icon-red: $mf-color-alert-red;
$mf-color-icon-orange: #f56900;
$mf-color-icon-yellow: #c28900;
$mf-color-icon-dark: #0a1541;
$mf-color-icon-grey: #6c728d;
$mf-color-icon-white: #fff;
$mf-color-icon-search: $mf-color-night-sky-60;

// Third party colors.
$mf-color-word: #2a5893;
$mf-color-powerpoint: #d24625;
$mf-color-pdf: #ff2116;

// Features
$mf-color-icon-favorite: #f1c234;

$mf-color-hub-active: $mf-color-alert-green;
$mf-color-hub-design: $mf-color-alert-yellow;
$mf-color-hub-inactive: $mf-color-alert-red;
$mf-color-hub-wizard: $mf-color-icon-grey;
