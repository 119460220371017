// common class for layout
.mf-layout-base {
    display: grid;
    grid-gap: 16px;
}

// use as a base class to set an element as a grid of 12 columns
.mf-layout {
    @extend .mf-layout-base;
    grid-template-columns: repeat(12, 1fr);
}

// use to set an element as a grid of $i columns
@for $i from 1 through 11 {
    .mf-layout-#{$i} {
        @extend .mf-layout-base;
        grid-template-columns: repeat($i, 1fr);
    }
}
